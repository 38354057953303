import React from 'react';

class Funko extends React.Component {
    componentDidMount() {
        console.log('Pick A Bingo Board');
    }

    render() {
        return (
            <div className="menu">
                <ul>
                    <li><a href="/nerdsunite">Nerds Unite Bingo Board</a></li>
                    {/* <li><a href="/funko">Funko Funkast Bingo Board</a></li> */}
                    <li><a href="/dorksmen">The Dorksmen Podcast Bingo Board</a></li>
                    <li><a href="/stsguys">STSGuys Podcast Bingo Board</a></li>
                    <li><a href="/toyrewind">Toy Rewind Podcast Bingo Board</a></li>
                    {/* <li><a href="/secondaryheroes">Secondary Heroes Podcast Bingo Board</a></li> */}
                    <li><a href="/geektogether">Geek Together Podcast Bingo Board</a></li>
                    {/* <li><a href="/totallyrad">Totally Rad Movie Podcast Bingo Board</a></li> */}
                    <li><a href="/movieretakes">Movie Retakes Podcast Bingo Board</a></li>
                    <li><a href="/averagenerd">The Average Nerd Podcast Bingo Board</a></li>
                    <li><a href="/moviegap">The Movie Gap Podcast Bingo Board</a></li>
                </ul>
            </div>
        );
    }
}

export default Funko;