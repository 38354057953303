import React from 'react';
import { Route } from "react-router-dom";
import Default from "../pages";
import Funko from "../pages/funko";
import Dorksmen from "../pages/dorksmen";
import Dorksmen50 from "../pages/dorksmen50";
import LDS from "../pages/lds";
import GeekTogether from "../pages/geektogether";
import STSGuys from "../pages/stsguys";
import TotallyRad from "../pages/totallyrad";
import SecondaryHeroes from "../pages/secondaryheroes";
import MovieRetakes from "../pages/movieretakes";
import ToyRewind from "../pages/toyrewind";
import Marca from "../pages/marca";
import NerdsUnite from "../pages/nerdsunite";
import AverageNerd from "../pages/averagenerd";
import MovieGap from "../pages/moviegap";

function Main() {
    return (
        <main>
            <Route exact path="/" component={Default} />
            <Route exact path="/funko" component={Funko} />
            <Route exact path="/dorksmen" component={Dorksmen} />
            <Route exact path="/dorksmen50" component={Dorksmen50} />
            <Route exact path="/lds" component={LDS} />
            <Route exact path="/geektogether" component={GeekTogether} />
            <Route exact path="/stsguys" component={STSGuys} />
            <Route exact path="/totallyrad" component={TotallyRad} />
            <Route exact path="/secondaryheroes" component={SecondaryHeroes} />
            <Route exact path="/movieretakes" component={MovieRetakes} />
            <Route exact path="/toyrewind" component={ToyRewind} />
            <Route exact path="/marca" component={Marca} />
            <Route exact path="/nerdsunite" component={NerdsUnite} />
            <Route exact path="/averagenerd" component={AverageNerd} />
            <Route exact path="/moviegap" component={MovieGap} />
        </main>
    );
}

export default Main;
