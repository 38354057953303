import React from 'react';
import Board from '../../components/board';

class Page extends React.Component {
    componentDidMount() {
        console.log('LDS Bingo');
        let root = document.getElementById('root');
        let title = document.querySelector('.appName h1');

        root.setAttribute('class', 'lds');
        title.innerHTML = 'GENERAL CONFERENCE BINGO';
    }

    render() {
        return (
            <Board />
        );
    }
}

export default Page;