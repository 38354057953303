import React from 'react';
import Card from '../components/card';
import CenterCard from '../components/centerCard';

function Board() {
    return (
        <section className="bingoBoard">
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <CenterCard />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
            <Card />
        </section>
    );
}

export default Board;
