import React from 'react';
import Main from './components/main';

function App() {
    return (
        <div className="App">
            <header className="appName">
                <h1>Bingo Boards</h1>
            </header>

            <Main />
        </div>
    );
}

export default App;
