import React from 'react';

function Card() {
    return (
        <div className="bingoCard">
            <p></p>
        </div>
    );
}

export default Card;