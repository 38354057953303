import React from 'react';

function CenterCard() {
    return (
        <div className="freeSpace">
            <p>Free Space</p>
        </div>
    );
}

export default CenterCard;